import React from 'react';
import './projekte.css';
import '../media-queries/gesamtuebersicht-media-queries.css';
import { graphql, Link } from 'gatsby';
import BasicStructure from '../components/basic-structure/basic-structure';
import ContentContainer from '../components/content-container/content-container';
import WpColumns from '../components/wp-blocks/wp-columns';
import TitleMetaTags from '../components/title-meta-tags/title-meta-tags';
import Footer from '../components/footer/footer';
import Header from '../components/header/header';
import Menubar from '../components/menu/menubar';
import BurgerMenu from '../components/burgermenu/burgermenu';
import Sidebar from '../components/sidebar/sidebar';
import ScrollUp from '../components/scroll-up/scroll-up';

interface Props {
  data: any;
}

export default function Projekte(props: Props): JSX.Element {
  const page = props.data?.wpPage;
  // console.log('projekte', page, props.data);
  const pageTitle = page?.title || '';
  const childPages = page?.wpChildren?.nodes;
  return (
    <BasicStructure>
      <Header>
        <TitleMetaTags title={pageTitle} />
        <Menubar styleClass="main-menu" />
        <BurgerMenu />
      </Header>
      <Sidebar>
        <ScrollUp />
      </Sidebar>
      <ContentContainer>
        <div className="projekte-overview">
          {childPages &&
            childPages?.length > 0 &&
            childPages
              ?.sort((a, b) => (a?.menuOrder || 0) - (b?.menuOrder || 0))
              .map((edge) => {
                const projectNode = edge;
                const excerptColumns = projectNode?.blocks?.find(
                  (b) => b.__typename === 'WpCoreColumnsBlock',
                );
                // console.log(
                //   'projectNode',
                //   projectNode,
                //   'excerptCols',
                //   excerptColumns,
                // );

                if (
                  projectNode &&
                  excerptColumns &&
                  excerptColumns.__typename === 'WpCoreColumnsBlock'
                ) {
                  return (
                    <section
                      key={projectNode.title}
                      className="project-excerpt layout--margin-bottom"
                    >
                      <a href={projectNode.uri}>
                        <h1 className="project-excerpt__title">
                          {projectNode.title}
                        </h1>
                      </a>
                      <div className="project-excerpt__content">
                        <WpColumns
                          key={excerptColumns.clientId}
                          block={excerptColumns}
                        />
                        <Link
                          className="navigation-next__link"
                          to={projectNode.slug ?? ''}
                        />
                      </div>
                    </section>
                  );
                }
              })}
        </div>
      </ContentContainer>
      <Footer />
    </BasicStructure>
  );
}

export const pageQuery = graphql`
  query ProjectsOverview {
    wpPage(uri: { eq: "/projekte/" }) {
      id
      title
      content
      uri
      wpChildren {
        nodes {
          id
          link
          slug
          ... on WpPage {
            id
            menuOrder
            title
            content

            blocks {
              __typename
              ...WPColumns
              ... on WpCoreHeadingBlock {
                saveContent
                attributes {
                  ... on WpCoreHeadingBlockAttributes {
                    align
                    anchor
                    content
                  }
                }
              }
            }
          }
          uri
        }
      }
    }
  }
`;
